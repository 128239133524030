import React, { useEffect, useState } from "react";
import { useToast, useTheme } from "@chakra-ui/react";
import { get, omit } from "lodash";
import { navigate } from "gatsby";
import { Field, Form as FinalForm } from "react-final-form";
import { stringify } from "query-string";
import { useLocation, Location } from "@reach/router";

import { get as GET, post } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import Heading from "../../base/Heading";
import Button from "../../base/Button";
import Spinner from "../../base/Spinner";
import Table from "../../Table";
import Icon from "../../Icon";
import Dropdown from "../../base/Dropdown";
import {
  ALL_FLEET,
  STATUS,
  TOTAL_JUMP_PAGES,
} from "../../../utilities/constants";
import dashboardConstants from "../../../utilities/dashboard-constants";
import generateSearchString from "../../../utilities/generate-search-string";
import responsiveCss from "../../../utilities/responsiveCss";
import removeTrailingSlashes from "../../../utilities/removeTrailingSlashes";
import DashboardPaginator from "../../CommonComponents/DashboardPaginator";
import Modal from "../../base/Modal";
import searchMultiple from "../../../utilities/searchMultiple";
import Input from "../../base/Input";

const UnitAssociationListingPage = () => {
  const theme = useTheme();
  const toast = useToast();
  const location = useLocation();
  const [unitAssociation, setUnitAssociation] = useState(null);
  const [unitAssociationData, setUnitAssociationData] = useState({});
  const [sample, setSample] = useState(true);
  const [cityDropDownOptions, setCityDropDownOptions] = useState([]);
  const [systemDropDownOptions, setSystemDropDownOptions] = useState([]);
  const [subsystemDropDownOptions, setSubsystemDropDownOptions] = useState([]);
  const [lineDropDownOptions, setLineDropDownOptions] = useState([]);
  const [unitDropDownOptions, setUnitDropDownOptions] = useState([]);
  const [nodeDropDownOptions, setNodeDropDownOptions] = useState([]);
  const [filter, setFilter] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const sampleToggle = () => {
    setSample(!sample);
  };
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };
  const { filters, pageCriteria } = dashboardConstants.getCriteria({
    search: location.search,
    type: "unitAssociation",
  });
  const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  );

  useEffect(async () => {
    try {
      const { data } = await GET("/listing-cities");
      const cityDropDownData = data.map((city) => ({
        label: `#${get(city, "city_id")} - ${get(city, "city")}`,
        value: get(city, "city_id"),
      }));
      setCityDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...cityDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
      });
      const data = await GET(`/listing-system?${generateSearchString(query)}`);
      const systemDropDownData = get(data, "data", []).map((system) => ({
        label: `#${get(system, "system_id")} - ${get(system, "system")}`,
        value: get(system, "system_id"),
      }));
      setSystemDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...systemDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
      });
      const data = await GET(
        `/listing-subsystem?${generateSearchString(query)}`
      );
      const subsystemDropDownData = get(data, "data", []).map((subsystem) => ({
        label: `#${get(subsystem, "subsystem_id")} - ${get(
          subsystem,
          "subsystem"
        )}`,
        value: get(subsystem, "subsystem_id"),
      }));
      setSubsystemDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...subsystemDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
      });
      const data = await GET(`/listing-line?${generateSearchString(query)}`);
      const lineDropDownData = get(data, "data", []).map((line) => ({
        label: `#${get(line, "line_id")} - ${get(line, "line")}`,
        value: get(line, "line_id"),
      }));
      setLineDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...lineDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
      });
      const data = await GET(`/listing-unit?${generateSearchString(query)}`);
      const unitDropDownData = get(data, "data", []).map((unit) => ({
        label: `#${get(unit, "unit_id")} - ${get(unit, "unit")}`,
        value: get(unit, "unit_id"),
      }));
      setUnitDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...unitDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = location.search.includes("unit_id")
        ? await GET(`/listing-assoc?${generateSearchString(query)}`)
        : await GET(`/listing-nodes?`);
      const nodeDropDownData = get(data, "data", []).map((node) => ({
        label: `#${get(node, "node_id")} - ${
          get(node, "name") || get(node, "node")
        }`,
        value: get(node, "node_id"),
      }));
      setNodeDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...nodeDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = await GET(`/listing-assoc?${generateSearchString(query)}`);

      if (data) {
        const filteredAvailableNodeData = searchValue
          ? searchMultiple(get(data, "data", []), searchValue)
          : get(data, "data", []);

        setUnitAssociationData({ ...data, data: filteredAvailableNodeData });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [sample, location]);

  const tableHeader = [
    { label: "#", value: "assoc_id" },
    { label: "Nodos", value: "name" },
    { label: "Unidade", value: "unit" },
    { label: "Ciudad", value: "city" },
    { label: "Sistema", value: "system" },
    { label: "Sub-sistema", value: "subsystem" },
    { label: "Línea", value: "line" },
    { label: "IMEI", value: "imei" },
    { label: "Mac ethernet", value: "mac_eth" },
    { label: "Mac W1", value: "mac_w1" },
    { label: "Mac W2", value: "mac_w2" },
    { label: "Model", value: "model" },
    { label: "Serial", value: "serial" },
    { label: "Acciones", value: "actions" },
  ];
  const pageTitle = "Listado de asociaciones de unidades";

  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  return (
    <Route isPrivate layout="admin">
      <Section sx={{ mx: "auto" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Heading {...theme.variant.heading}>{pageTitle}</Heading>
          <Button
            onClick={() => {
              navigate("/unitAssociation/add-unitAssociation");
            }}
          >
            Añadir nueva asociación
          </Button>
        </Box>
        <Box>
          <FinalForm
            onSubmit={async () => {}}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", mb: 8 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      mb: 2,
                    }}
                  >
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="city_id"
                      onChange={(value) => {
                        const filterObject =
                          value === ALL_FLEET
                            ? omit(
                                filter,
                                ["city_id"],
                                ["system_id"],
                                ["subsystem_id"],
                                ["line_id"],
                                ["unit_id"]
                              )
                            : omit(
                                { ...filter, city_id: value },
                                ["system_id"],
                                ["subsystem_id"],
                                ["line_id"],
                                ["unit_id"]
                              );
                        setFilter(filterObject);
                        const searchParams = stringify({
                          filters: stringify(filterObject),
                          page: stringify({
                            limit: 10,
                            offset: 0,
                          }),
                        });
                        navigate(
                          `${removeTrailingSlashes(location.pathname)}${
                            searchParams ? "?" : ""
                          }${searchParams}`,
                          {
                            replace: true,
                          }
                        );
                      }}
                      options={cityDropDownOptions}
                      placeholder="Select City"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="system_id"
                      onChange={(value) => {
                        const filterObject =
                          value === ALL_FLEET
                            ? omit(
                                filter,
                                ["system_id"],
                                ["subsystem_id"],
                                ["line_id"],
                                ["unit_id"]
                              )
                            : omit(
                                { ...filter, system_id: value },
                                ["subsystem_id"],
                                ["line_id"],
                                ["unit_id"]
                              );
                        setFilter(filterObject);
                        const searchParams = stringify({
                          filters: stringify(filterObject),
                          page: stringify({
                            limit: 10,
                            offset: 0,
                          }),
                        });
                        navigate(
                          `${removeTrailingSlashes(location.pathname)}${
                            searchParams ? "?" : ""
                          }${searchParams}`,
                          {
                            replace: true,
                          }
                        );
                      }}
                      options={systemDropDownOptions}
                      placeholder="Select System"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="subsystem_id"
                      onChange={(value) => {
                        const filterObject =
                          value === ALL_FLEET
                            ? omit(
                                filter,
                                ["subsystem_id"],
                                ["line_id"],
                                ["unit_id"]
                              )
                            : omit(
                                { ...filter, subsystem_id: value },
                                ["line_id"],
                                ["unit_id"]
                              );
                        setFilter(filterObject);
                        const searchParams = stringify({
                          filters: stringify(filterObject),
                          page: stringify({
                            limit: 10,
                            offset: 0,
                          }),
                        });
                        navigate(
                          `${removeTrailingSlashes(location.pathname)}${
                            searchParams ? "?" : ""
                          }${searchParams}`,
                          {
                            replace: true,
                          }
                        );
                      }}
                      options={subsystemDropDownOptions}
                      placeholder="Select Subsystem"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      mb: 2,
                    }}
                  >
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="line_id"
                      onChange={(value) => {
                        const filterObject =
                          value === ALL_FLEET
                            ? omit(filter, ["line_id"], ["unit_id"])
                            : omit({ ...filter, line_id: value }, ["unit_id"]);
                        setFilter(filterObject);
                        const searchParams = stringify({
                          filters: stringify(filterObject),
                          page: stringify({
                            limit: 10,
                            offset: 0,
                          }),
                        });
                        navigate(
                          `${removeTrailingSlashes(location.pathname)}${
                            searchParams ? "?" : ""
                          }${searchParams}`,
                          {
                            replace: true,
                          }
                        );
                      }}
                      options={lineDropDownOptions}
                      placeholder="Select Line"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="unit_id"
                      onChange={(value) => {
                        const filterObject =
                          value === ALL_FLEET
                            ? omit(filter, ["unit_id"], ["node_id"])
                            : omit({ ...filter, unit_id: value }, ["node_id"]);
                        setFilter(filterObject);
                        const searchParams = stringify({
                          filters: stringify(filterObject),
                          page: stringify({
                            limit: 10,
                            offset: 0,
                          }),
                        });
                        navigate(
                          `${removeTrailingSlashes(location.pathname)}${
                            searchParams ? "?" : ""
                          }${searchParams}`,
                          {
                            replace: true,
                          }
                        );
                      }}
                      options={unitDropDownOptions}
                      placeholder="Select Unit"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="node_id"
                      onChange={(value) => {
                        const filterObject =
                          value === ALL_FLEET
                            ? omit(filter, ["node_id"])
                            : { ...filter, node_id: value };
                        setFilter(filterObject);
                        const searchParams = stringify({
                          filters: stringify(filterObject),
                          page: stringify({
                            limit: 10,
                            offset: 0,
                          }),
                        });
                        navigate(
                          `${removeTrailingSlashes(location.pathname)}${
                            searchParams ? "?" : ""
                          }${searchParams}`,
                          {
                            replace: true,
                          }
                        );
                      }}
                      options={nodeDropDownOptions}
                      placeholder="Select Node"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      mb: 2,
                    }}
                  >
                    <Box
                      as={Field}
                      component={Input}
                      id="serial"
                      name="serial"
                      onChange={(e) => {
                        setSearchValue({
                          ...searchValue,
                          serial: e.target.value,
                        });
                        sampleToggle();
                      }}
                      placeholder="Serial"
                      sx={{
                        ...theme.variant.input.primary,
                        // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                        mr: 4,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                      type="text"
                      wrapperSx={colStyled}
                    />
                    <Box
                      as={Field}
                      component={Input}
                      id="mac_eth"
                      name="mac_eth"
                      onChange={(e) => {
                        setSearchValue({
                          ...searchValue,
                          mac_eth: e.target.value,
                        });
                        sampleToggle();
                      }}
                      placeholder="Mac Ethernet"
                      sx={{
                        ...theme.variant.input.primary,
                        // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                        mr: 4,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                      type="text"
                      wrapperSx={colStyled}
                    />
                    <Box
                      as={Field}
                      component={Input}
                      id="imei"
                      name="imei"
                      onChange={(e) => {
                        setSearchValue({
                          ...searchValue,
                          imei: e.target.value,
                        });
                        sampleToggle();
                      }}
                      placeholder="IMEI"
                      sx={{
                        ...theme.variant.input.primary,
                        // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                        mr: 4,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                      type="text"
                      wrapperSx={colStyled}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      mb: 2,
                    }}
                  >
                    <Box
                      as={Field}
                      component={Input}
                      id="mac_w1"
                      name="mac_w1"
                      onChange={(e) => {
                        setSearchValue({
                          ...searchValue,
                          mac_w1: e.target.value,
                        });
                        sampleToggle();
                      }}
                      placeholder="Mac W1"
                      sx={{
                        ...theme.variant.input.primary,
                        // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                        mr: 4,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                      type="text"
                      wrapperSx={colStyled}
                    />
                    <Box
                      as={Field}
                      component={Input}
                      id="mac_w2"
                      name="mac_w2"
                      onChange={(e) => {
                        setSearchValue({
                          ...searchValue,
                          mac_w2: e.target.value,
                        });
                        sampleToggle();
                      }}
                      placeholder="Mac W2"
                      sx={{
                        ...theme.variant.input.primary,
                        // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                        mr: 4,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                      type="text"
                      wrapperSx={colStyled}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "flex-end",
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      justifyContent: "flex-end",
                    }}
                  >
                    <Location>
                      {({ location: { pathname } }) => (
                        <Button
                          onClick={() => {
                            formContext.form.reset({
                              city_id: "",
                              imei: "",
                              line_id: "",
                              mac_eth: "",
                              mac_w1: "",
                              mac_w2: "",
                              node_id: "",
                              serial: "",
                              subsystem_id: "",
                              system_id: "",
                              unit_id: "",
                            });
                            navigate(pathname);
                          }}
                          sx={{ maxWidth: "100px" }}
                          variant="primary"
                        >
                          Reset
                        </Button>
                      )}
                    </Location>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
        <Box>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Table
                header={tableHeader}
                renderEmpty="Sin datos"
                rows={get(unitAssociationData, "data", []).map(
                  (unitAssociation) => [
                    get(unitAssociation, "assoc_id", "-"),
                    get(unitAssociation, "node", "-"),
                    get(unitAssociation, "unit", "-"),
                    get(unitAssociation, "city", "-"),
                    get(unitAssociation, "system", "-"),
                    get(unitAssociation, "subsystem", "-"),
                    get(unitAssociation, "line", "-"),
                    get(unitAssociation, "imei", "-"),
                    get(unitAssociation, "mac_eth", "-"),
                    get(unitAssociation, "mac_w1", "-"),
                    get(unitAssociation, "mac_w2", "-"),
                    get(unitAssociation, "model", "-"),
                    get(unitAssociation, "serial", "-"),
                    <Box
                      as="button"
                      color="mediumGreen"
                      onClick={() => {
                        setUnitAssociation(get(unitAssociation, "assoc_id"));
                        deleteToggle();
                      }}
                    >
                      <Icon svg="remove" sx={{ width: "18px" }} />
                    </Box>,
                  ]
                )}
                tableSx={{
                  borderCollapse: "collapse",
                  overflow: "visible",
                }}
              />
              {unitAssociationData.total > 0 && (
                <DashboardPaginator
                  buttonSx={{
                    color: "primary",
                  }}
                  enableJumpToPage
                  filters={filters}
                  pageCriteria={pageCriteria}
                  pageTotal={get(unitAssociationData, "data", []).length}
                  total={get(unitAssociationData, "total")}
                  totalJumpToPages={totalJumpToPages}
                />
              )}
            </>
          )}
        </Box>
        <Modal
          bodySx={{ pb: 0 }}
          footer={
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                onClick={async () => {
                  const data = await post("/del-assoc", {
                    assoc_id: unitAssociation,
                  });
                  toastMessage({
                    message:
                      get(data, "success") === STATUS.INACTIVE
                        ? get(data, "msm")
                        : "Association deleted successfully!",
                    toast,
                    type:
                      get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                  deleteToggle();
                  sampleToggle();
                }}
                sx={{ mr: 3 }}
              >
                Yes
              </Button>{" "}
              <Button
                onClick={() => {
                  deleteToggle();
                }}
                sx={{ ml: 3 }}
              >
                Cancel
              </Button>
            </Box>
          }
          onClose={deleteToggle}
          open={deleteModal}
        >
          <Box
            sx={{
              color: "mediumGreen",
              display: "flex",
              justifyContent: "center",
              my: 5,
            }}
          >
            Are you sure to delete this association?
          </Box>
        </Modal>
      </Section>
    </Route>
  );
};
export default UnitAssociationListingPage;
