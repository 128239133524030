import React from "react";
import { Router } from "@reach/router";

import UnitAssociationListingPage from "../components/PageComponents/UnitAssociationListingPage";
import UnitAssociationAddPage from "../components/PageComponents/UnitAssociationAddPage";

const unitAssociation = (props) => (
  <Router>
    <UnitAssociationListingPage {...props} path="/unitAssociation" />
    <UnitAssociationAddPage
      {...props}
      path="/unitAssociation/add-unitAssociation"
    />
  </Router>
);

export default unitAssociation;
