import React, { useEffect, useState } from "react";
import { useTheme, useToast } from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import { Field, Form as FinalForm } from "react-final-form";
import toastMessage from "../../../utilities/toastMessage";
import Box from "../../base/Box";
import { required } from "../../../validations";
import FormError from "../../base/FormError";
import Button from "../../base/Button";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Heading from "../../base/Heading";
import { get as GET, post } from "../../../utilities/http";
import Dropdown from "../../base/Dropdown";
import { STATUS } from "../../../utilities/constants";

const UnitAssociationAddForm = ({ location }) => {
  const theme = useTheme();
  const toast = useToast();
  const gap = 7;
  const [cityDropDownOptions, setCityDropDownOptions] = useState([]);
  const [systemDropDownOptions, setSystemDropDownOptions] = useState([]);
  const [subsystemDropDownOptions, setSubsystemDropDownOptions] = useState([]);
  const [lineDropDownOptions, setLineDropDownOptions] = useState([]);
  const [unitDropDownOptions, setUnitDropDownOptions] = useState([]);
  const [nodeDropDownOptions, setNodeDropDownOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [selectedSubsystem, setSelectedSubsystem] = useState(null);
  const [selectedLine, setSelectedLine] = useState(null);
  const unit = get(location, "state.unitAssociation", null);
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: "60%",
    mb: gap,
    mx: 4,
    width: "50%",
  };
  const colFull = {
    minWidth: "100%",
  };
  useEffect(async () => {
    try {
      const { data } = await GET("/listing-cities");
      const dropDownData = data.map((city) => ({
        label: `#${get(city, "city_id")} - ${get(city, "city")}`,
        value: get(city, "city_id"),
      }));
      setCityDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  useEffect(async () => {
    try {
      const { data } = selectedCity
        ? await GET(`/listing-system?city_id=${selectedCity}`)
        : await GET("/listing-system");
      const dropDownData = data.map((system) => ({
        label: `#${get(system, "system_id")} - ${get(system, "system")}`,
        value: get(system, "system_id"),
      }));
      setSystemDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [selectedCity]);
  useEffect(async () => {
    try {
      const { data } = selectedSystem
        ? await GET(`/listing-subsystem?system_id=${selectedSystem}`)
        : await GET("/listing-subsystem");
      const dropDownData = data.map((subsystem) => ({
        label: `#${get(subsystem, "subsystem_id")} - ${get(
          subsystem,
          "subsystem"
        )}`,
        value: get(subsystem, "system_id"),
      }));
      setSubsystemDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [selectedSystem]);
  useEffect(async () => {
    try {
      const { data } = selectedSubsystem
        ? await GET(`/listing-line?subsystem_id=${selectedSubsystem}`)
        : await GET("/listing-line");
      const dropDownData = data.map((line) => ({
        label: `#${get(line, "line_id")} - ${get(line, "line")}`,
        value: get(line, "line_id"),
      }));
      setLineDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [selectedSubsystem]);
  useEffect(async () => {
    try {
      const { data } = selectedLine
        ? await GET(`/listing-unit?line_id=${selectedLine}`)
        : await GET("/listing-unit");
      const dropDownData = data.map((unit) => ({
        label: `#${get(unit, "unit_id")} - ${get(unit, "unit")}`,
        value: get(unit, "unit_id"),
      }));
      setUnitDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [selectedLine]);
  useEffect(async () => {
    try {
      const { data } = await GET("/available-nodes");
      const dropDownData = data.map((node) => ({
        label: `#${get(node, "node_id")} - ${get(node, "name")}`,
        value: get(node, "node_id"),
      }));
      setNodeDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  return (
    <Route isPrivate layout="admin">
      <Section>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: ["90%", null, null, "70%"],
            mb: 4,
            mx: "auto",
          }}
        >
          <Heading {...theme.variant.heading}>
            {unit ? "Asociación de actualización" : "Añadir nueva asociación"}
          </Heading>
        </Box>
        <Box
          sx={{
            bg: "white",
            borderRadius: 4,
            maxWidth: ["90%", null, "70%"],
            mx: "auto",
            p: 10,
          }}
        >
          <FinalForm
            initialValues={{
              city: get(unit, "city_id"),
              line: get(unit, "line_id"),
              node: get(unit, "node_id"),
              subsystem: get(unit, "subsystem_id"),
              system: get(unit, "system_id"),
              unit: get(unit, "unit_id"),
            }}
            onSubmit={async (values) => {
              try {
                const payload = {
                  city_id: values.city,
                  line_id: values.line,
                  node_id: values.node,
                  subsystem_id: values.subsystem,
                  system_id: values.system,
                  unit_id: values.unit,
                };
                const data = await post("/add-assoc", payload);

                const message =
                  data && get(data, "success")
                    ? get(data, "msm")
                    : "Association Added!";
                if (data) {
                  toastMessage({
                    message,
                    toast,
                    type:
                      data && get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                }
                navigate("/unitAssociation");
              } catch (e) {
                toastMessage({
                  message: e.message,
                  toast,
                  type: "error",
                });
              }
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="city"
                    onChange={(value) => {
                      setSelectedCity(value);
                    }}
                    options={cityDropDownOptions}
                    placeholder="Ciudad"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="system"
                    onChange={(value) => {
                      setSelectedSystem(value);
                    }}
                    options={systemDropDownOptions}
                    placeholder="Sistema"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="subsystem"
                    onChange={(value) => {
                      setSelectedSubsystem(value);
                    }}
                    options={subsystemDropDownOptions}
                    placeholder="Sub-sistema"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="line"
                    onChange={(value) => {
                      setSelectedLine(value);
                    }}
                    options={lineDropDownOptions}
                    placeholder="Líneas"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="unit"
                    options={unitDropDownOptions}
                    placeholder="Unidades"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="node"
                    options={nodeDropDownOptions}
                    placeholder="Nodos"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      my: 5,
                      ...colFull,
                    }}
                  >
                    <Button
                      disabled={formContext.pristine || formContext.submitting}
                      submitting={formContext.submitting}
                      sx={{ mr: 3, width: "130px" }}
                      type="submit"
                      variant="primary"
                    >
                      Guardar
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/unitAssociation  ");
                      }}
                      sx={{ ml: 3, width: "130px" }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
      </Section>
    </Route>
  );
};

export default UnitAssociationAddForm;
